/* eslint no-eval: 0 */
import React, { Component } from 'react';
import { Row } from 'reactstrap';
import helper from '../services/helper';
import Base from './Base';

// const refBase = React.createRef();
class FormSchema extends Component {

  checkError() {
    for (let i = 0; i < this.props.schema.length; i++) {
      console.log(
        'check invalid',
        this.isInvalid(
          this.props.schema[i],
          this.props.data[this.props.schema[i].field]
        )
      );
      if (this.props.schema[i].widget === 'TableInput') {
        // console.log('FormSchema -> checkError -> this.props.schema[i].widget', this.props.schema[i].widget);
        console.log("refBase", this.refBase)
        // console.log("refBase.current.getError(): ", refBase.current.getError());
        if (this.refBase) {
          if (this.refBase.getError()) {
            return i;
          }
        }
      }
      if (
        this.isInvalid(
          this.props.schema[i],
          this.props.data[this.props.schema[i].field]
        )
      ) {
        return i;
      }
    }
    return -1;
  }
  onSubmit() {
    let error = this.checkError();
    if (error > 0)
      return helper.alert(
        `Dữ liệu chưa đúng, kiểm tra lại thông tin ${this.props.schema[error].name}`
      );
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  }
  isInvalid(schema, value) {
    if (
      schema.required &&
      (value === null || value === undefined || value === '')
    )
      return true;
    return false;
  }
  render() {
    if (!this.props.schema) return <p>Chưa định nghĩa schema</p>;
    //convert default value
    this.props.schema.map(s => {
      if (this.props.data[s.field] === undefined && s.default)
        this.props.data[s.field] = s.default;
      return null;
    });
    return (
      <form
        autoComplete="off"
        onSubmit={evt => {
          evt.preventDefault();
          if (this.props.onSubmit) {
            this.props.onSubmit();
          }
        }}
      >

<Row>
        {this.props.schema.map((comp, index) => {
          if (comp.hideExpression) {
            let str = comp.hideExpression;
            for (var i in this.props.data) {
              str = helper.replaceAll(str, i, this.props.data[i]);
            }
            try {
              if (window.eval(str)) return null;
            } catch (err) { }
          }
          return (
            <Base
              ref={ref => this.refBase = ref}
              key={index}
              schema={comp}
              onChange={e => {
                let dt = Object.assign({}, this.props.data);
                // console.log(dt, 'pppp');
                dt[comp.field] = e;
                if (this.props.onChange) {
                  this.props.onChange(dt);
                }
              }}
              data={this.props.data}
              value={this.props.data[comp.field]}
              itemId={this.props.itemId || null}
            />
          );
        })}
        {this.props.children}
        </Row>
      </form>
    );
  }
}

export default FormSchema;
